import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery, Link } from "gatsby"
import blogStyles from "./blog.module.scss"
import Head from "../components/head"

const BlogPage = () => {
  // ****** Local Filesystem Query ******
  // const data = useStaticQuery(graphql`
  //   query {
  //     allMarkdownRemark {
  //       edges {
  //         node {
  //           frontmatter {
  //             title
  //             date
  //           }
  //           fields {
  //             slug
  //           }
  //         }
  //       }
  //     }
  //   }
  // `).allMarkdownRemark.edges.sort((postA, postB) => {
  //   const dateA = postA.node.frontmatter.date
  //   const dateB = postB.node.frontmatter.date
  //   if (dateA > dateB) return -1
  //   if (dateB > dateA) return 1
  //   return 0
  // })

  const contentfulData = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: postDate, order: DESC }) {
        edges {
          node {
            title
            slug
            postDate(formatString: "YYYY-MM-DD")
          }
        }
      }
    }
  `).allContentfulBlogPost.edges

  return (
    <Layout>
      <Head title="Blog" />
      <h1>Blog</h1>
      <ol className={blogStyles.posts}>
        {contentfulData.map(post => (
          <li className={blogStyles.post} key={post.node.slug}>
            <Link to={post.node.slug}>
              <h2>{post.node.title}</h2>
              <p>{post.node.postDate}</p>
            </Link>
          </li>
        ))}
      </ol>
    </Layout>
  )
}

export default BlogPage
